<template>
  <aside class="container mt-4">
    <h3 class="mt-2">Hinweise</h3>
    <p><strong>Potentielle Risiken der Anteilszeichnung</strong></p>
    <p>
      Auch Genossenschaftsanteile der WTF Kooperative eG sind eine unternehmerische Beteiligung. Grundsätzlich kann bei jeder unternehmerischen Beteiligung ein Totalverlust nicht ausgeschlossen werden. Die Satzung sieht keine Verzinsung der Anteile vor, dies kann gem. GenG § 21a durch Beschluss der Generalversammlung geändert werden und auf einen ausgewiesenen Jahresüberschuss bzw. Gewinnvortrag anschließend erfolgen. Die Rückzahlung von gekündigten Genossenschaftsanteilen kann sich, u. a. aufgrund der Liquiditätslage der Genossenschaft, über den in der Satzung geregelten Zeitraum hinaus (6 Monate nach Ablauf der Kündigungsfrist) verzögern. Der reale Wert der Anteile nach Erwerb kann durch Verluste auch geringer ausfallen als der satzungsgemäße Nennwert. Eine Nachschusspflicht ist durch die Satzung ausgeschlossen.
    </p>
    <p><strong>Datenschutzerklärung</strong></p>
    <p>
      Wir erheben, speichern und verarbeiten die personenbezogenen Daten unserer Mitglieder ausschließlich in dem für die Ausübung der Mitgliedschaft erforderlichen Umfang. Eine Weitergabe der Mitgliedsdaten an Dritte erfolgt grundsätzlich nicht und wird bei gesetzlichen Maßnahmen mitgeteilt.
    </p>
  </aside>
 <aside class="container no-print mt-4" id="faq">
    <h3 class="mt-2">FAQ</h3>
    <div>
      <h4>Warum muss ich den Antrag per Post abschicken?</h4>
      <p>Germany, Baby! Das Land, in dem Bürokratie und Papiertiger regieren!</p>
    </div>
    <div>
      <h4>Was macht der QR-Code?</h4>
      <p>Dieser speichert in einer semantischen Art und Weise Deine Daten. Das ermöglicht es dem Office, Deinen Antrag schneller zu bearbeiten!</p>
    </div>
    <div>
      <h4>Was ist ein Genossenschaftsanteil?</h4>
      <p>Genossenschaftsanteile sind "Geschäftsanteile an einer Genossenschaft". Ein bisschen ist das wie die Aktien in einer AG, mit dem Unterschied, dass Du in einer Genossenschaft Dein Geld langfristiger anlegst und sie als Hauptziel nicht die Gewinnmaximierung hat, sondern die wirtschaftliche Absicherung ihrer Mitglieder. Den Wert eines Anteils kann eine Genossenschaft selbst festlegen. Wir haben uns für 100 € entschieden, da man damit einfach rechnen kann und wir ihn weder "zu klein" noch "zu groß" finden. Wer mehr anlegen möchte, zeichnet einfach mehr Anteile (die maximale Anzahl haben wir pro Mitglied auf 100 Anteile beschränkt, und unabhängig von der Anteilsanzahl hat jedes Mitglied das gleiche Stimmgewicht, z. B. bei der Generalversammlung).</p>
    </div>
    <div>
      <h4>Wie viele Anteile sollte ich zeichnen?</h4>
      <p>Unsere Empfehlung sind fünf Anteile als Richtwert, Du kannst aber schon ab einem Anteil bei uns Mitglied werden. Es ist übrigens möglich, Anteile in monatlichen Raten zu zahlen. Der erste Anteil sowie die einmalige Aufnahmegebühr müssen allerdings überwiesen sein, bevor wir Deinen Antrag weiter bearbeiten können.</p>
    </div>
    <div>
      <h4>Warum muss ich die Personendaten von meinem Personalausweis angeben?</h4>
      <p>
        Gem. <a href="https://dejure.org/gesetze/GenG/30.html">§30 GenG</a> sind wir verpflichtet die Mitglieder in einer Mitgliederliste zu führen.Eine regelmäßige Identifikation von Mitgliedern kann beispielsweise für die Teilnahme an der Generalversammlung oder im Einzelfall für die Wiederherstellung des Zugangs notwendig werden.Die Herausgabe der Daten einzelner oder aller Mitglieder kann durch Gesetze vorgeschrieben werden.
      </p>
    </div>
    <div>
      <h4>Wieso kann ich meine Pronomen angeben?</h4>
      <p>Du weißt am besten, wie Du gerne von uns angesprochen werden möchtest. Wenn Du uns das mitteilst, können wir darauf achten und machen weniger Fehler. Die Angabe ist selbstverständlich freiwillig. Wenn wir keine Informationen dazu haben, folgen wir unserem "best guess" :)</p>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'TheFaq'
}
</script>
